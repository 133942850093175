<template>
  <el-main>
      <!-- 搜索 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="关键词标题：">
        <el-input size="small" placeholder="请输入关键词标题" v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="用户昵称：">
        <el-input size="small" placeholder="请输入用户昵称" v-model="form.nickname"></el-input>
      </el-form-item>
     <el-form-item label="上架时间：">
        <el-date-picker value-format="timestamp" v-model="form.show_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="到期时间：">
        <el-date-picker value-format="timestamp" v-model="form.out_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="会员手机号：">
        <el-input size="small" placeholder="请输入会员手机号" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="跳转类型：">
         <el-select  v-model="form.goto_type" placeholder="请选择跳转类型" size="small" filterable clearable>
            <el-option
            v-for="item in goto_type"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="title" label="关键词标题" align="center" width="110"></el-table-column>
         <el-table-column prop="nickname" label="用户昵称" align="center" width="160">
          <template slot-scope="scope">
            <div class="row user">
              <el-image :src="scope.row.avatar" alt=""></el-image>
              <div class="goods_name">{{scope.row.nickname}}</div>
            </div>
          </template>
         </el-table-column>
        <el-table-column prop="mobile" label="会员手机号" align="center" width="110"></el-table-column>
        <el-table-column prop="goto_type" label="跳转类型" align="center">
          <template slot-scope="scope">
            {{goto_type.find(item=>item.value==scope.row.goto_type).label}}
          </template>
        </el-table-column>
        <el-table-column prop="remaining_sum" label="现存竞价金额" align="center" width="110">
          <template slot-scope="scope">
            ￥{{scope.row.remaining_sum}}
          </template>
        </el-table-column>
         <el-table-column prop="ranking" label="关键词排名" align="center" width="110"></el-table-column>
         <el-table-column label="跳转商品" align="center" width="200">
          <template slot-scope="scope">
            <div class="row">
              <el-image :src="scope.row.goto_picture" alt=""></el-image>
              <div class="goods_name">{{scope.row.goto_name}}</div>
            </div>
          </template>
         </el-table-column>
        <el-table-column prop="update_time" width="160" label="上架时间" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" width="160" label="到期时间" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="展示状态" align="center" class="status">
          <template slot-scope="scope">
            <span :style="{'color':scope.row.status=='展示中'?'#409EFF':'#F56C6C'}">{{ scope.row.status}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="$router.push('/extension/keywords/order')">查看订单</el-button>
            <el-button type="text" size="small"  @click="consumeLog(scope.row.id)">消耗明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    <!-- 消耗明细 -->
    <el-dialog title="消耗明细" :visible.sync="dialogVisible"  width="50%" >
        <el-table :data="detail_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="update_time" width="160" label="消耗时间" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
         <el-table-column label="消耗用户信息" align="center">
          <template slot-scope="scope">
            <div class="row user">
              <el-image :src="scope.row.avatar" alt=""></el-image>
              <div class="goods_name">{{scope.row.nickname}}</div>
            </div>
          </template>
         </el-table-column>
        <el-table-column prop="mobile" label="消耗用户手机号" align="center"></el-table-column>
        <el-table-column prop="amount" label="消耗" align="center">
          <template slot-scope="scope">
            <span style="color:#F56C6C;">-{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
      </el-table>
       <Paging :total="detail_total" :page="detail_form.page" :pageNum="detail_form.rows" @updatePageNum="updateData2"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import {goto_type} from './data'
export default {
  components: {
    Paging,
  },
  data() {
    return {
      total: 0,
      form:{
          page: 1,
          rows: 10,
          title:'',
          nickname:'',
          show_time:'',
          out_time:'',
          mobile:'',
          goto_type:'0'
      },
      goto_type:goto_type,
      list: [],
      detail_form:{
        page: 1,
        rows: 10,
        id:'',
      },
      detail_list:[],
      detail_total:0,
      dialogVisible:false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.form.page = 1;
      this.getList();
    },
    cancelSearch() {
        this.form = {
          page: 1,
          rows: 10,
          title:'',
          nickname:'',
          show_time:'',
          out_time:'',
          mobile:'',
          goto_type:'0'
        }
        this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getList();
      } else {
        this.form.page = val;
        this.getList();
      }
    },
    updateData2(val, status){
        if (status == 0) {
            this.detail_form.rows = val;
            this.consumeLogList();
        } else {
            this.detail_form.page = val;
            this.consumeLogList();
        }
    },
    getList() {
      let data = Object.assign({},this.form)
      if(data.show_time){
        data.start_show_time = data.show_time[0]/1000
        data.end_show_time = data.show_time[1]/1000
      }
      if(data.out_time){
        data.start_out_time = data.out_time[0]/1000
        data.end_out_time = data.out_time[1]/1000
      }
      delete data.show_time
      delete data.out_time
      this.$axios.post(this.$api.gateway.keywords.list, data)
      .then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat,
    consumeLog(id){
      this.detail_form.id = id
      this.consumeLogList()
    },
    consumeLogList(){
      this.$axios.post(this.$api.gateway.keywords.consumeLog, this.detail_form)
      .then(res => {
        if (res.code == 0) {
          this.detail_list = res.result.list;
          this.detail_total = res.result.total_number;
          this.dialogVisible = true
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;
  /deep/ .el-input__inner {
    border-color: #fff;
  }
  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}
.el-main {
  background: #fff;
}
.row{
  display:flex;
  align-items: center;
  .el-image{
    width: 50px;
    height:50px;
    margin-right:10px;
  }
  .goods_name{
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    flex:1;
    text-align:left;
  }
}
.user{
  .el-image{
    width: 40px;
    height:40px;
    border-radius: 50%;
  }
}
</style>
